@import '@/assets/styles/global.scss';

.Register {
  $self: &;
  &-card {
    width: 100%;
    max-width: 550px;
    padding: 50px;

    @include mobile {
      padding: 50px 15px;
    }
  }

  &-steps {
    margin-top: 40px;
  }

  &-form {
    margin-top: 10px;
  }

  &-label {
    margin-bottom: 30px;
    color: var(--bg-header-color);
    font-size: 14px;
    font-family: Ubuntu;
    font-weight: 500;
    line-height: 20px;
  }

  &-types {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &-type {
    text-align: center;
    cursor: pointer;
    position: relative;
    svg {
      margin-bottom: 10px;

      path {
        &:first-child {
          fill: var(--gray-color-100);
        }
      }
    }

    &_active {
      svg {
        path {
          &:first-child {
            fill: var(--button-primary-bg);
          }
        }
      }

      #{$self} {
        &-text {
          color: var(--button-primary-bg);
        }
      }
    }
  }

  &-text {
    color: var(--body-color);
  }

  &-radio {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -99999;
    opacity: 0;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }

  &-bottom {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }

  &-verify {
    margin-top: 20px;
  }
  &-code {
    margin-top: 10px;
  }
}
