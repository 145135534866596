@import '@/assets/styles/global.scss';

.Items {
  display: flex;
  flex-wrap: wrap;

  &_bottom {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_column {
    flex-direction: column;
  }

  &_row {
    flex-direction: row;
  }
}
